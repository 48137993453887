import * as React from "react"
import Layout from "../component/layout.js"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import favicon from "../images/icon.png"

const NotFoundPage = () => {
  return(<Layout>
    <Helmet>
      <title>404 Error - BartonWhite.me</title>
      <link rel="icon" href={favicon} />">
    </Helmet>
      <section id="hero">
      <div className="container">
        <div className="twelve columns tac">
          <h1><strong>Page not found</strong></h1>
          <p>What? How'd you get here? <br /> Go to my <strong><Link to="/">home page</Link></strong> to learn more about me and see my projects.</p>
        </div>
      </div>
      
    </section>
    </Layout>)
}

export default NotFoundPage
